import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import { APP_ROUTES } from "../../../system/router/constants";
import {
  ADMIN_INFO_URL,
  ADMIN_PROFILE,
  authDataType,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  LOGOUT_URL,
  RESET_PASSWORD_URL,
  SSO_GET_TOKENS,
  SSO_LOGOUT_URL,
} from "../constants";

import {
  useApiRequest,
  useSSOApiRequest,
} from "../../../system/hooks/useApiRequest";
import { generatePath, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../../system/helpers/helpfulFunctions";
import { ErrorType } from "../../../system/types/constants";
import { useHandleLoginSuccess } from "./hooks";
import { useSSOAxiosClient } from "../../../system/api/axiosConfig";
import config from "../../../system/config";
import { resetStorage } from "./helpers";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../system/reducers";
import { useDispatch } from "react-redux";
import { AxiosError } from "axios";
import setSSOToken from "./actions";
import { LS_KEYS } from "../../../system/constants/localStorage";


// TODO: remove after successfull SSO intergation
export const useLogin = () => {
  const { apiRequest } = useApiRequest();
  const handleHUBSuccessLogin = useHandleLoginSuccess();

  return useMutation({
    mutationFn: (data: any): Promise<authDataType> =>
      apiRequest({
        type: "LOGIN",
        request: {
          url: LOGIN_URL,
          method: "POST",
          data: data,
        },
      }),
    onSuccess: (data) => handleHUBSuccessLogin(data),
    onError: (err: any) => {
      console.log(err);
      message.error(err.error ? err.error.data.message : "Login error message");
    },
  });
};

export const useSSOLogout = () => {
  const ssoClient = useSSOAxiosClient();
  const { apiRequest } = useSSOApiRequest(ssoClient);

  return useMutation({
    mutationKey: [SSO_LOGOUT_URL],
    mutationFn: () =>
      apiRequest({
        type: "LOGOUT",
        request: {
          url: SSO_LOGOUT_URL,
          method: "POST",
        },
      }),
    onSuccess: () => {
      resetStorage();
      window.location.assign(config.SSO_DOMAIN_URL);
    },
    onError: (err: any) => {
      console.log(err);
      message.error(
        err.error ? err.error.data.message : "Logout error message"
      );
    },
  });
};

// TODO: remove after successfull SSO intergation
export const useLogout = () => {
  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();
  const ssoToken = useSelector((state: ApplicationState) => state.auth?.sso_token);

  return useMutation({
    mutationFn: (token: string) =>
      apiRequest({
        type: "LOGOUT",
        request: {
          url: generatePath(LOGOUT_URL, { auth: token }),
          method: "DELETE",
        },
      }),
    onSuccess: () => {
      resetStorage();

      // TODO: remove after successfull SSO intergation
      if (ssoToken) {
        window.location.assign(config.SSO_DOMAIN_URL);
      } else {
        navigate(APP_ROUTES.login);
      }
    },
    onError: (err: any) => {
      console.log(err);
      message.error(
        err.error ? err.error.data.message : "Logout error message"
      );
    },
  });
};

// TODO: remove after successfull SSO intergation
export const useForgotPassword = () => {
  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (data: any) =>
      apiRequest({
        type: "FORGOT_PASSWORD",
        request: {
          url: FORGOT_PASSWORD_URL,
          method: "POST",
          data: data,
        },
      }),
    onSuccess: (data: any) => {
      navigate(APP_ROUTES.login);
      message.success(
        !!data.message
          ? data.message
          : "You will receive an email with instructions about how to\n reset your password in a few minutes."
      );
    },
    onError: (err: any) => {
      console.log(err);
      message.error(
        err.error ? err.error.data.message : "Forgot password error message"
      );
    },
  });
};

// TODO: remove after successfull SSO intergation
export const useResetPassword = () => {
  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: any): Promise<authDataType> =>
      apiRequest({
        type: "RESET_PASSWORD",
        request: {
          url: RESET_PASSWORD_URL,
          method: "POST",
          data: data,
        },
      }),
    onSuccess: (data) => {
      navigate(APP_ROUTES.login);
      message.success(
        !!data.message
          ? data.message
          : "Password has been reset successfully. \n" +
              "Use the new password to login."
      );
    },
    onError: (err: any) => {
      console.log(err);
      message.error(
        err.error ? err.error.data.message : "Reset password error message"
      );
    },
  });
};

export const useAdminProfile = () => {
  const { apiRequest, apiErrors } = useApiRequest();

  return useQuery({
    queryKey: [ADMIN_PROFILE],
    queryFn: () =>
      apiRequest({
        type: "ADMIN_INFO",
        request: {
          url: ADMIN_INFO_URL,
          method: "GET",
        },
      }),
    enabled: isLoggedIn(),
    throwOnError: (err: ErrorType) => apiErrors(err),
  });
};

export const useOAuth = () => {
    const { apiRequest } = useSSOApiRequest();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleHUBSuccessLogin = useHandleLoginSuccess();
  
    return useMutation<any, AxiosError<any>, any>({
      mutationKey: [SSO_GET_TOKENS],
      mutationFn: ({ codeVerifier, code }) =>
        apiRequest({
          request: {
            url: SSO_GET_TOKENS,
            data: {
              grant_type: "authorization_code",
              client_id: config.DEFAULT_CLIENT_ID,
              redirect_uri: config.REDIRECT_URL,
              code_verifier: codeVerifier,
              code,
            },
            method: "POST",
          },
        }),
      onSuccess(data) {
        let redirectLocation = localStorage.getItem(LS_KEYS.LOCATION);
  
        if (!redirectLocation) {
          return;
        } else if (redirectLocation === APP_ROUTES.oauth) {
          redirectLocation = APP_ROUTES.dashboard;
        }
  
        localStorage.setItem(LS_KEYS.TOKEN, data?.internal?.access_token);
        localStorage.setItem(
          LS_KEYS.TOKEN_EXPIRATION,
          data?.internal?.expires_in
        );
        localStorage.removeItem(LS_KEYS.CODE_VERIFIER);
        localStorage.removeItem(LS_KEYS.LOCATION);
  
        dispatch(setSSOToken(data?.internal?.access_token));
        handleHUBSuccessLogin(data?.external, false);
        navigate(redirectLocation);
      },
    });
  };
  

