import React, { PropsWithChildren } from "react";
import { useGetBootstrapInfo } from "../../modules/Bootstrap/api/apiHooks";

const SystemTemplate: React.FC<PropsWithChildren> = ({ children }) => {
  useGetBootstrapInfo();

  return <>{children}</>;
};

export default SystemTemplate;
