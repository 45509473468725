import { LS_KEYS } from "../../../system/constants/localStorage";
import { removeCookie } from "../../../system/helpers/cookies";
import {
  generateCodeChallenge,
  generateCodeVerifier,
  generateOAuthURL,
} from "../../../system/helpers/oauth";

export const resetStorage = () => {
  removeCookie("token");
  localStorage.removeItem(LS_KEYS.TOKEN_EXPIRATION);
  localStorage.removeItem(LS_KEYS.TOKEN);
  localStorage.removeItem("brand");
  localStorage.removeItem("authData");
};

export const verifySSOToken = (location) => {
  const code_verifier = generateCodeVerifier();
  const code_challenge = generateCodeChallenge(code_verifier);
  const oauthUrl = generateOAuthURL({ code_challenge });
  const currentLocation = localStorage.getItem(LS_KEYS.LOCATION);

  if (!currentLocation) {
    localStorage.setItem(LS_KEYS.LOCATION, location.pathname);
  }

  localStorage.setItem(LS_KEYS.CODE_VERIFIER, code_verifier);

  window.location.assign(oauthUrl);
};
