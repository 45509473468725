import { useCallback, useLayoutEffect } from "react";
import { resetStorage, verifySSOToken } from "../../modules/Auth/api/helpers";
import setSSOToken from "../../modules/Auth/api/actions";
import { useSSOLogout } from "../../modules/Auth/api/apiHooks";
import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers";

const loadSSOWidget = (config: SSOWidgetSettings) => {
  try {
    return new Promise(function (resolve, reject) {
      const isAlreadyAdded = document.getElementById("sso-widget-script");
      if (isAlreadyAdded) return;

      const script = document.createElement("script");
      script.src = `https://sso-widget.stage.gamingsphere.xyz/index.js?timestamp=${Date.now()}`;
      script.async = true;
      script.id = "sso-widget-script";
      script.onload = function () {
        if (typeof window.initWidget === "function") {
          window.initWidget(config);
        }
      };
      script.onerror = function () {
        reject("SSO Widget: Failed to load the script");
      };
      document.head.appendChild(script);
    });
  } catch (err) {}
};

export const getSSOWidgetModeByEnv = () => {
  const projectEnv: string = process.env.REACT_APP_ENV || "";
  const appDevEnv = process.env.REACT_APP_DEVELOPMENT_ENV || "";

  switch (projectEnv) {
    case "development":
      return appDevEnv === "proxy" ? "development-with-proxy" : "dev";
    case "staging":
      return "stage";
    case "production":
      return "prod";
    default:
      return "stage";
  }
};

export const useSSOWidget = (cfg: Partial<SSOWidgetSettings>) => {
  const { rootSelector, mode = getSSOWidgetModeByEnv() } = cfg || {};
  const { mutate: onLogout } = useSSOLogout();
  const token = useSelector(
    (state: ApplicationState) => state.auth.sso_token,
    shallowEqual
  );

  const onError = useCallback((err) => {
    if (err.status === 401) {
      resetStorage();
      setSSOToken(null);
      verifySSOToken(window.location);
    }
  }, []);

  useLayoutEffect(() => {
    if (token) {
      loadSSOWidget({ rootSelector, token, mode, onLogout, onError });
    }
  }, [token, onLogout, onError, rootSelector, mode]);
};
