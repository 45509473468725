import React, { useEffect } from "react";
import { ConfigProvider, Layout } from "antd";
import Sidebar from "../../components/organisms/Sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { NotLoggedUser } from "../NotLoggedUser";
import { APP_ROUTES } from "../../system/router/constants";
import AntPageHeader from "../../components/organisms/PageHeader";
import { useAdminProfile } from "../../modules/Auth/api/apiHooks";
import { useConfig } from "../../system/hooks/useConfig";
import { ReactComponent as HubMainLogo } from "../../images/icons/logo/hub-logo-full.svg";
import { themeConfig } from "./themeConfig";

const MainTemplate = ({ children }) => {
  let { pathname } = useLocation();
  let navigate = useNavigate();

  const { isGlobalConfig } = useConfig();

  useAdminProfile();

  useEffect(() => {
    const brandPagesRoutes = [
      APP_ROUTES.translations,
      APP_ROUTES.customers,
      APP_ROUTES.quiz,
    ];
    const brand = JSON.parse(localStorage.getItem("brand"));

    if (!brand && brandPagesRoutes.includes(pathname)) {
      navigate(APP_ROUTES.dashboard);
    }
  }, [pathname, navigate, isGlobalConfig]);

  // TODO: remove after successfull SSO intergation
  if (pathname === APP_ROUTES.login || pathname === APP_ROUTES.resetPassword) {
    return <NotLoggedUser children={children} />;
  }

  return (
    <ConfigProvider theme={themeConfig}>
      <Layout style={{ minHeight: "100%" }}>
        <Sidebar className="sidebar" logo={<HubMainLogo className="logo" />} />
        <Layout className="layout">
          <AntPageHeader />
          <Layout.Content className="content-container">
            <div className="site-layout-background">{children}</div>
          </Layout.Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default MainTemplate;
