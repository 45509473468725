import React, { useEffect } from "react";
import { useLogin } from "../../../modules/Auth/api/apiHooks";
import AuthWrapper from "../../../modules/Auth/components/wrappers/AuthWrapper";
import LoginForm from "../../../modules/Auth/components/forms/LoginForm";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../system/router/constants";
import { LS_KEYS } from "../../../system/constants/localStorage";

const Login = () => {
  const { mutate, isPending } = useLogin();
  const navigate = useNavigate();

  const onSubmit = (values) =>
    mutate({
      email: values.email,
      password: values.password,
    });

  useEffect(() => {
    // TODO: remove after successfull SSO integration
    const ssoToken = localStorage.getItem(LS_KEYS.TOKEN);
    if (ssoToken) {
      navigate(APP_ROUTES.dashboard);
    }
  }, [navigate]);

  return (
    <AuthWrapper isLoading={isPending} pageContent="Hello, who's this?">
      <LoginForm onSubmit={onSubmit} />
    </AuthWrapper>
  );
};

export default Login;
