import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = ({ fontSize }) => (
  <Spin indicator={<LoadingOutlined style={{ fontSize: fontSize }} spin />} />
);

Loader.defaultProps = {
  fontSize: 24,
};

export default Loader;
