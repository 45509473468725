import CryptoJs from "crypto-js";
import config from "../config";

export const generateCodeVerifier = (length = 128) => {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_.~";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return randomString;
};

export const generateCodeChallenge = (codeVerifier) => {
  return CryptoJs.SHA256(codeVerifier)
    .toString(CryptoJs.enc.Base64)
    .replaceAll(/=+$/g, "")
    .replaceAll("+", "-")
    .replaceAll("/", "_");
};

export const generateOAuthURL = ({
  sso_domain = config.SSO_DOMAIN_URL,
  client_id = config.DEFAULT_CLIENT_ID,
  redirect_uri = config.REDIRECT_URL,
  code_challenge,
  state = ""
}) => {
  return `${sso_domain}/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&code_challenge=${code_challenge}&code_challenge_method=S256&state=${state}`;
};
