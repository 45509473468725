import { useEffect } from "react";

import { getQueryParams } from "../../system/helpers/urlHelper";
import FullScreenLoader from "../../components/molecules/FullScreenLoader";
import { LS_KEYS } from "../../system/constants/localStorage";
import { useOAuth } from "../../modules/Auth/api/apiHooks";

const OAuth = () => {
  const code = getQueryParams(window.location.search).get("code");
  const codeVerifier = localStorage.getItem(LS_KEYS.CODE_VERIFIER);
  const { mutate: generateToken, isPending } = useOAuth();

  useEffect(() => {
    if (code && !isPending && codeVerifier) {
      generateToken({ codeVerifier, code });
    }
  }, [code, codeVerifier, isPending, generateToken]);

  return <FullScreenLoader />;
};

export default OAuth;
