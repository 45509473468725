import * as actionTypes from "../constants";
import { authDataType } from "../constants";

const initialState: authDataType = {
  id: null,
  name: "",
  email: "",
  active: true,
  settings: [],
  roles: [],
  brands: [],
  permissions: [],
  isLoading: false,
  message: "",
  sso_token: null,
  success: false,
  token: "",
  socket: "",
  profile: {
    id: null,
    name: "",
    email: "",
    active: false,
    setting: [],
    roles: [
      {
        id: null,
        name: "",
        guard_name: "",
        permissions: [],
      },
    ],
    permissions: [],
    brands: [],
  },
};

export default function auth(state: authDataType = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_SSO_TOKEN:
      return { ...state, sso_token: payload.token };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...payload.profile,
        token: payload.token,
        isLoading: false,
      };
    case actionTypes.LOGOUT_START:
      return { ...state };
    case actionTypes.LOGOUT_ERROR:
    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialState };
    case actionTypes.LOGIN_START:
    case actionTypes.ADMIN_INFO_START:
      return { ...state, isLoading: true };
    case actionTypes.ADMIN_INFO_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case actionTypes.LOGIN_ERROR:
    case actionTypes.ADMIN_INFO_ERROR:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
