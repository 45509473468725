import axios from "axios";
import backendUrl from "./apiUrl";
import { isLoggedIn } from "../helpers/helpfulFunctions";
import { getCookie } from "../helpers/cookies";
import config from "../config";
import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../reducers";
import { AxiosRequestConfig } from "axios";

const axiosClient = () =>
  axios.create({
    baseURL: backendUrl,
    responseType: "json",
    headers: {
      "X-Admin-Token": isLoggedIn() ? getCookie("token") : "",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const useSSOAxiosClient = () => {
  const token = useSelector(
    (state: ApplicationState) => state.auth.sso_token,
    shallowEqual
  );
  const axiosInstance = axios.create({
    baseURL: `${config.BASE_API_URL}/api`,
    responseType: "json",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const addTokenInterceptor = useCallback(
    <T extends AxiosRequestConfig>(cfg: T) => {
      cfg.headers.Authorization = `Bearer ${token}`;
      return cfg;
    },
    [token]
  );

  axiosInstance.interceptors.request.use(addTokenInterceptor);

  return axiosInstance;
};

export const useOAuthAxiosClient = () => {
  const axiosInstance = axios.create({
    baseURL: config.BASE_API_URL,
    responseType: "json",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic cGlsb3Q6NEM5WVtEZ3ZRUTVq",
    },
  });

  return axiosInstance;
};

export default axiosClient;
